import React, { memo } from 'react';
import { FooterIcon } from '~/lib/data-contract';
import { Image } from '~/shared/components';
import { StyledImageContainer } from '../styled';

export const Icons = memo(({ icons }: { icons: FooterIcon[] }) => {
    return (
        <StyledImageContainer>
            {icons.map(
                ({ image, title }, index) =>
                    image?.src && (
                        <Image
                            src={image.src}
                            key={image.src + index}
                            title={title}
                            loading="lazy"
                            width={35}
                            height={24}
                            layout="intrinsic"
                            objectFit="contain"
                        />
                    )
            )}
        </StyledImageContainer>
    );
});
