import React from 'react';
import Basket from '$icons/shopping-cart.svg';
import { useBasket, MiniBasket } from '~/features/basket';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { useTranslation } from '~/shared/utils/translation';
import { usePotentialThemeColor } from '~/theme';
import { StyledBasketButton, StyledIconBadge } from '../styled';
import { useFrame } from '~/shared/utils';
import { RouterLink } from '~/shared/components';

export const MiniBasketButton = () => {
    const backgroundColor = usePotentialThemeColor('black');
    const { data: basket, isMiniBasketOpen, totalQuantity } = useBasket();
    const { data } = useFrame();
    const { translate } = useTranslation();

    const { lines = [] } = basket || {};

    const basketTrigger = (
        <RouterLink href={data?.staticLinks?.basketPage?.url || ''}>
            <StyledBasketButton active={isMiniBasketOpen} as="a">
                <Basket aria-hidden="true" />
                <VisuallyHidden children={translate('navigation.mainMenu.basket')} />
                {lines?.length ? (
                    <StyledIconBadge backgroundColor={backgroundColor} children={totalQuantity} />
                ) : null}
            </StyledBasketButton>
        </RouterLink>
    );

    return <MiniBasket triggerComponent={basketTrigger} />;
};
