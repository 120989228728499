import React, { memo } from 'react';
import { SocialLink } from '~/lib/data-contract';
import { Image, Text, Link, RawHtml } from '~/shared/components';
import { StyledBackground, StyledMeta, StyledMetaRTEWrapper, StyledSoMeItems } from './styled';
import { weakKey } from '~/shared/utils/jsx';
import { useTheme } from '@emotion/react';

type MetaAreaProps = {
    copyRightNotice?: string;
    soMeLinks?: SocialLink[];
    footerMeta?: string;
};

export const MetaArea = memo(({ copyRightNotice, soMeLinks, footerMeta }: MetaAreaProps) => {
    const { colors } = useTheme();

    return (
        <StyledBackground>
            {footerMeta && (
                <StyledMetaRTEWrapper>
                    <RawHtml html={footerMeta} backgroundColor="dark" linkStrokeMode="whileHover" />
                </StyledMetaRTEWrapper>
            )}
            <StyledMeta>
                {copyRightNotice && <Text style={{ color: colors.light }}>{copyRightNotice}</Text>}
                <StyledSoMeItems>
                    {soMeLinks?.map(({ link, image }) =>
                        link ? (
                            <Link
                                prefetch={false}
                                href={link?.url || ''}
                                passHref={Boolean(link)}
                                key={weakKey(link)}
                                target={link?.target || '_blank'}
                                title={link?.text}
                                rel="noopener nofollow"
                                type="plain"
                            >
                                {image?.src && (
                                    <Image
                                        src={image.src}
                                        width={24}
                                        height={24}
                                        alt={image.alt}
                                        layout="intrinsic"
                                    />
                                )}
                            </Link>
                        ) : null
                    )}
                </StyledSoMeItems>
            </StyledMeta>
        </StyledBackground>
    );
});
